import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, interval, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { BaseApiService } from '../baseApi.service';
import { User } from 'src/app/models/user';
import { AuthResponse } from 'src/app/models/auth';
import { Agency } from 'src/app/models/agency';
import { NotificationService } from '../notification.service';
import { StorageService } from '../storage.service';
import { Api } from '../interfaces/api';

export interface AuthState extends Partial<AuthResponse> {}
@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseApiService implements Api {
  public endpoint = '/auth';
  public isItalianAgency: boolean = true;

  public auth = signal<AuthState>(this.localStorageAuthState ?? {});
  public did_show_modal = false;

  get state(): AuthState {
    return this.auth();
  }

  set state(authData: AuthState | null) {
    this.auth.set({ ...authData });
    this.localStorageAuthState = authData;
  }

  constructor(
    httpClient: HttpClient,
    notification: NotificationService,
    private router: Router,
    private localStorage: StorageService,
    private toast: NotificationService
  ) {
    super(httpClient, notification);

    const REFRESH_TIMER = 30 * 60 * 1000; // refresh token of TC every X min
    this.refreshTokenTimer(REFRESH_TIMER);
  }

  private get localStorageAuthState(): AuthState | null {
    const local = JSON.parse(
      this.localStorage.getItem('currentUser') ?? '{}'
    ) as AuthState;
    if (local) {
      return local;
    }
    return null;
  }

  private set localStorageAuthState(authState: AuthState | null) {
    if (authState !== null) {
      this.localStorage.setItem('currentUser', JSON.stringify(authState));
    } else {
      this.localStorage.removeItem('currentUser');
    }
  }

  set didShowModal(value: boolean) {
    this.did_show_modal = value;
    this.localStorage.setItem('showModal', value.toString());
  }

  get didShowModal(): boolean {
    return this.localStorage.getItem('showModal') === 'true';
  }

  get isLoggedIn(): boolean {
    return this.auth()?.user?.id != null;
  }
  public get isAdmin(): boolean {
    return !!this.state?.user?.is_admin;
  }

  public get isAgency(): boolean {
    return !!this.state?.user?.agency_id;
  }

  public get isMain(): boolean {
    return !!this.state?.user?.is_main_user;
  }

  adminLogin(email: string, password: string): Observable<any> {
    return this.post(`/admin/authenticate`, { email, password }).pipe(
      map((auth: AuthResponse) => {
        this.state = { ...auth };
        this.didShowModal = false;
        return auth.user;
      })
    );
  }

  login(username: string, password: string): Observable<any> {
    return this.post(`/authenticate`, { username, password }).pipe(
      map((auth: AuthResponse) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.state = { ...auth };
        this.didShowModal = false;
        return this.state.user;
      })
    );
  }

  register(newAgency: FormData): Observable<any> {
    if (this.isItalianAgency) {
      // Italian Agencies
      return this.post(`/register`, newAgency).pipe(
        map((agency: Agency) => {
          return agency;
        })
      );
    } else {
      // Foreign Agencies
      return this.post(`/register-foreign-agency`, newAgency).pipe(
        map((agency: Agency) => {
          return agency;
        })
      );
    }
  }

  resetAdmin(email: string, url: string): Observable<any> {
    return this.post(`/admin/forgot-password`, { email, url });
  }

  resetPassword(payload: {
    password: string;
    password_confirmation: string;
    token: string;
  }): Observable<any> {
    return this.post(`/admin/reset-password`, payload);
  }

  // credentials: {username: string, password: string})

  reset(username: string): Observable<any> {
    return this.post(`/forgot-password`, { username });
  }

  /**
   * Verify an Agency
   * @param vatNumber number
   */
  verify(vatNumber: number | string, nation?: string): Observable<any> {
    if (this.isItalianAgency) {
      return this.post(`/italian-vat-verification`, { vat_id: vatNumber });
    } else {
      return this.post(`/foreign-vat-verification`, {
        vat_id: vatNumber,
        country_code: nation,
      });
    }
  }
  // verify(vatNumber: number | string): Observable<any> {
  //     if (this.isItalianAgency) {
  //         return this.httpWithoutInterceptor.post(`/api/open-api/italian-vat-verification`, { vat_number: vatNumber });
  //     } else {
  //         return this.httpWithoutInterceptor.post(`/api/open-api/foreign-vat-verification`, { vat_number: vatNumber });
  //     }
  // }

  /**
   * Refresh TC (Travel Compositor) token
   *
   */

  private refreshToken(): Observable<boolean> {
    return this.post('/tc-token').pipe(
      take(1),
      map((response: AuthResponse) => {
        const authTokens = {
          ...this.state,
          ...response,
        } as AuthState;
        this.state = authTokens;
        // this.toast.info('Refreshing TC Token...');
        return true;
      }),
      catchError((error) => {
        this.toast.error(
          'Invalid API Authentication, could not refresh token.'
        );
        this.logout().subscribe();
        return of(false);
      })
    );
  }

  logout(): Observable<any> {
    if (!this.isLoggedIn) {
      return EMPTY;
    }
    return this.post('/logout', null).pipe(
      tap(() => this.clearCurrentUser()),
      catchError((err) => {
        this.clearCurrentUser();
        throwError(() => err);
        return EMPTY;
      })
    );
  }

  clearCurrentUser(): void {
    this.state = null;
    this.localStorage.removeItem('currentUser');
    this.router
      .navigate(['/auth/login'], { queryParamsHandling: 'merge' })
      .then();
  }

  private refreshTokenTimer(milliseconds: number): void {
    interval(milliseconds)
      .pipe(filter((x) => this.isLoggedIn))
      .subscribe(() => {
        console.log('Token expiring, Hence Refreshing...');
        this.refreshToken().subscribe();
      });
  }

  checkToken(): Observable<boolean> {
    if (!this.validateLocalStorageAuthState()) {
      return this.logout().pipe(map(() => false));
    }
    return this.validateAuthToken().pipe(
      switchMap((res: boolean) => {
        if (!res) {
          return this.logout().pipe(map(() => false));
        }
        if (this.hasTokenExpired() && this.isLoggedIn) {
          return this.refreshToken().pipe(map(() => true));
        }
        return of(true);
      })
    );
  }

  private validateLocalStorageAuthState(): boolean {
    const now = new Date();
    const exp = new Date(this.state?.expires_at ?? 0);
    if (
      this.state.access_token !== this.localStorageAuthState?.access_token ||
      this.state.tc_auth_token !== this.localStorageAuthState?.tc_auth_token
    ) {
      this.toast.error('Invalid Authentication detected.');
      return false;
    }
    return true;
  }

  private validateAuthToken(): Observable<boolean> {
    return this.post(`${'/validate-token'}`, {}).pipe(
      map((res: any) => res.valid ?? false),
      catchError((error) => {
        return of(false);
      })
    );
  }
  private hasTokenExpired(): boolean {
    if (!this.state.tc_expires_at) {
      return false;
    }
    const now = new Date();
    const exp = new Date(this.state.tc_expires_at);
    return exp < now;
  }
}
