import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, interval, Observable, of} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, take, tap} from 'rxjs/operators';
import {TokenService} from 'src/app/shared/services/storage/token.service';
import {BaseApiService} from '../baseApi.service';
import {User} from 'src/app/models/user';
import {Auth} from 'src/app/models/auth';
import {Agency} from 'src/app/models/agency';
import {NotificationService} from '../notification.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Api} from '../interfaces/api';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService implements Api {

  /**
   * @param endpoint public property of parent class
   */
  public endpoint = '/auth';  // URL to web api
  // used for diferentiating agencies in register page
  public isItalianAgency: boolean = true;
  // used to store logged in user.
  private currentUserSubject$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(this.storageToken.getUser());

  // private currentUserSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(this.storageToken.getUser() as User);
  // public currentUser = this.currentUserSubject$.asObservable().pipe(distinctUntilChanged());
  public readonly currentUser: Observable<User | null> = this.currentUserSubject$.asObservable().pipe(distinctUntilChanged());
  // Used to store token data
  private currentAuthTokenSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(this.storageToken.getAuth());
  public readonly currentAuthToken: Observable<Auth> = this.currentAuthTokenSubject$.asObservable().pipe(distinctUntilChanged());

  constructor(
    httpClient: HttpClient,
    notification: NotificationService,
    private router: Router,
    private storageToken: TokenService,
    private modalService: NgbModal
  ) {
    super(httpClient, notification);

    const REFRESH_TIMER = 10 * 60 * 1000; // refresh token of TC every X min
    this.refreshTokenTimer(REFRESH_TIMER);
  }

  public get currentAuthTokenValue(): Auth {
    return this.currentAuthTokenSubject$.getValue();
  }

  public get currentUserValue(): User | null {
    // return this.currentUserSubject$?.value;
    return this.currentUserSubject$.getValue();
  }

  public get isAdmin(): boolean {
    return Boolean(this.currentUserValue && this.currentUserValue.is_admin);
  }

  public get isAgency(): boolean {
    return Boolean(this.currentUserValue && this.currentUserValue.agency_id !== null);
  }

  public get isMain(): boolean {
    return Boolean(this.currentUserValue && this.currentUserValue.is_main_user);
  }

  public hasBackPermissionTo(action: string, section: string): boolean {
    if (this.currentUserValue) {
      const backend = this.currentUserValue?.permissions?.backend;
      // console.log('hasBackPermissionTo', action, section, backend);
      return Boolean(backend[section] && backend[section][action]);
    }
    return false;
  }

  public hasFrontPermissionTo(action: string, section: string): boolean {
    if (this.currentUserValue) {
      const frontend = this.currentUserValue?.permissions?.frontend;
      // console.log('hasFrontPermissionTo', action, section, frontend);
      return Boolean(frontend[section] && frontend[section][action]);
    }
    return false;
  }

  adminLogin(email: string, password: string): Observable<any> {

    return this.post(`/admin/authenticate`, {email, password}).pipe(map((auth: Auth) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      this._setAuthUsernData(auth);

      // return current loggedin user
      return auth.user;
    }));
  }

  resetAdmin(email: string, url: string): Observable<any> {
    return this.post(`/admin/forgot-password`, {email, url});
  }

  resetPassword(payload: { password: string, password_confirmation: string, token: string }): Observable<any> {
    return this.post(`/admin/reset-password`, payload);
  }


  // credentials: {username: string, password: string})
  login(username: string, password: string): Observable<any> {
    return this.post(`/authenticate`, {username, password}).pipe(map((auth: Auth) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      this._setAuthUsernData(auth);

      // return current loggedin user
      return auth.user;
    }));
  }

  register(newAgency: FormData): Observable<any> {
    if (this.isItalianAgency) {
      // Italian Agencies
      return this.post(`/register`, newAgency).pipe(map((agency: Agency) => {
        return agency;
      }));
    } else {
      // Foreign Agencies
      return this.post(`/register-foreign-agency`, newAgency).pipe(map((agency: Agency) => {
        return agency;
      }));
    }
  }

  reset(username: string): Observable<any> {
    return this.post(`/forgot-password`, {username});
  }

  /**
   * Verify an Agency
   * @param vatNumber number
   */
  verify(vatNumber: number | string): Observable<any> {
    return this.post(`/agency-verification`, {vat_number: vatNumber});
  }

  // verify(vatNumber: number | string): Observable<any> {
  //     if (this.isItalianAgency) {
  //         return this.httpWithoutInterceptor.post(`/api/open-api/italian-vat-verification`, { vat_number: vatNumber });
  //     } else {
  //         return this.httpWithoutInterceptor.post(`/api/open-api/foreign-vat-verification`, { vat_number: vatNumber });
  //     }
  // }

  /**
   * Refresh TC (Travel Compositor) token
   *
   */
  refreshToken(): void {
    const auth = this.storageToken.getAuth();

    this.post(`/tc-token`).pipe(
      take(1),
      tap((response) => {
        const authTokens = {
          ...auth,
          ...response,
          timestamp: new Date(),
        };
        this.storageToken.setAuth(authTokens);
        this.currentAuthTokenSubject$.next(authTokens);
      }),
      catchError((error) => {
        this.logout();
        return of(false);
      })
    ).subscribe();
  }

  logoutAction(): void {
    this.post(`/logout`).subscribe(_ => {
      this.logout();
    });
  }

  logout(): void {
    this.log(`Logout: remove all from storage`);
    // remove all from storage
    Promise.all([
      // remove anything from storage
      this.storageToken.resetAll(),
      // set subject to null for user
      this.currentUserSubject$.next(null),
      // sett token object to null
      this.currentAuthTokenSubject$.next(null),
      // close any open modal
      this.modalService.hasOpenModals() ? this.modalService.dismissAll() : '',
    ]).then(() => {
      // Redirect to login
      this.router.navigate(['/auth/login']).then(() => {
        window.location.href = `${environment.base_url}/logout-logs-dashboard`;
      });
    });
  }

  isLoggedIn(): boolean {
    const user = this.storageToken.getUser();
    // this.log('isLoggedIn', user);
    if (!!user && user.id) {
      return true;
    }
    return false;
  }

  /** GET Users from the server */
  getUsers(): Observable<User[]> {
    return this.get('').pipe(map((users: User[]) => {
      return users;
    }));
  }

  /**
   * Set user data to local storage
   */
  private _setAuthUsernData(auth: Auth): void {
    const authTokens = {
      access_token: auth.access_token,
      expires_at: auth.expires_at,
      login_at: auth.login_at,
      tc_auth_token: auth.tc_auth_token,
      tc_expiration_in_seconds: auth.tc_expiration_in_seconds,
      tc_login_at: auth.tc_login_at,
      tc_expires_at: auth.tc_expires_at,
      timestamp: new Date(),
    };

    this.storageToken.setUser(auth.user);
    this.storageToken.setToken(auth.access_token);
    this.storageToken.setAuth(authTokens);

    this.currentUserSubject$.next(auth.user);
    this.currentAuthTokenSubject$.next(authTokens);

    // const ifrm = document.createElement('iframe');
    // console.log(auth);
    // ifrm.setAttribute('src',
    //  `https://www.booking.atitur.com/IT/2/famiglia/moreideas?token=${auth.tc_auth_token}&submit=true`);
    // ifrm.style.width = '0';
    // ifrm.style.height = '0';
    // document.body.appendChild(ifrm);

    // console.log(ifrm);

    // setTimeout(() => {
    //     document.body.removeChild(ifrm);
    // }, 2000);

  }

  /** Log a UserService  message with the MessageService */
  private log(...args: any[]): void {
    console.log(`AuthService:`, ...args);
  }

  private refreshTokenTimer(milliseconds: number): void {
    interval(milliseconds).pipe(
      filter(x => this.isLoggedIn())
    ).subscribe(() => {
      console.log('Token expiring, Hence Refreshing...');
      this.refreshToken();
    });
  }

}
