import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree, Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // console.log('AuthGuard is called for canLoad', route);


    if (!!this.authService.isLoggedIn()) {
      // user is logged in, so return true
      return true;
    }

    // // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login']);

    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // console.log('AuthGuard is called for canActivate', route);

    if (!!this.authService.isLoggedIn()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['/auth/login']);

    return false;

  }

}
