// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  secure: false,
  mock: true,
  title: 'Atitur | Viaggi e vacanze su misura',
  url: 'http://newmondogattinoni.draft2017.com/',
  base_url: 'http://api.newmondogattinoni.draft2017.com/api',
  redirect_url: 'http://ppt.gattinonitravelstore.it',
  storage_prefix: 'prefix_',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
