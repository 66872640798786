import { Injectable } from '@angular/core';

import { Toastr } from './interfaces/toastr';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements Toastr {

  public type = ['question', 'info', 'success', 'warning', 'error'];
  public position = ['top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', 'bottom-end'];
  private toaster: any;

  constructor() {
    this.toaster = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer),
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }


  info(title?: string | undefined, message?: string | undefined): void {
    this.show('info', title, message);
  }

  success(title?: string | undefined, message?: string | undefined): void {
    this.show('success', title, message);
  }

  warning(title?: string | undefined, message?: string | undefined): void {
    this.show('warning', title, message);
  }

  error(title?: string | undefined, message?: string | undefined): void {
    this.show('error', title, message);
  }

  question(title?: string | undefined, message?: string | undefined): void {
    this.show('question', title, message);
  }

  show(
    icon: 'question' | 'info' | 'success' | 'warning' | 'error' | undefined,
    title?: string | undefined,
    text?: string | undefined
  ): void {
    this.toaster.fire({ icon, title, html: text });
  }
}
