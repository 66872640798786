import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({ providedIn: 'root' })

export class AuthGuard {
  /* If the user is not logged, don't allow accessing the route / child routes */
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.canAccessRoute();
  }

  canActivateChild(): Observable<boolean> {
    return this.canAccessRoute();
  }

  canMatch(): Observable<boolean> {
    return this.canAccessRoute();
  }

  canLoad(): Observable<boolean> {
    return this.canAccessRoute();
  }

  private canAccessRoute(): Observable<boolean> {
    if (this.authService.isLoggedIn) {
      return this.authService.checkToken();
    }
    this.router.navigateByUrl('/auth/login').then();
    return of(false);
  }
}
