import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FactoryService {

  localStorage: Storage;

  private inMemoryStorage: { [key: string]: any } = {};

  constructor() {
    this.localStorage = window.localStorage;
  }

  /**
   * Check if local storage is supported
   * @returns boolean
   */
  isSupported(): boolean {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      this.localStorage.setItem(testKey, testKey);
      this.localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      console.warn('local Storage is not supported switch to inMemory storage');
      return false;
    }
  }

  getItem(name: string): string | null {
    if (this.isSupported()) {
      return this.localStorage.getItem(name);
    }

    if (this.inMemoryStorage.hasOwnProperty(name)) {
      return this.inMemoryStorage[name];
    }

    return null;
  }

  key(index: number): string | null {
    if (this.isSupported()) {
      return this.localStorage.key(index);
    }

    if (this.inMemoryStorage[index]) {
      return this.inMemoryStorage[index];
    }

    return null;
  }

  removeItem(name: string): void {
    if (this.isSupported()) {
      this.localStorage.removeItem(name);
    } else {
      delete this.inMemoryStorage[name];
    }
  }

  setItem(name: string, value: string): void {
    if (this.isSupported()) {
      this.localStorage.setItem(name, value);
    } else {
      this.inMemoryStorage[name] = value;
    }
  }

  clear(): void {
    if (this.isSupported()) {
      this.localStorage.clear();
    } else {
      this.inMemoryStorage = {};
    }
  }


}
