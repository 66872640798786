import { Injectable } from '@angular/core';
import {
  Route,
  UrlSegment,
  UrlTree,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard {
  constructor(private router: Router, private authService: AuthService) {}

  canLoad(): boolean {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // console.log('NoAuthGuard is called for canActivate', route);
    if (!!this.authService.isLoggedIn) {
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }
}
