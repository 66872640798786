import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { LoaderService } from './loader.service';
import { LoaderState } from './loader';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription = new Subscription();

    constructor( private loaderService: LoaderService ) { }

    ngOnInit(): void {
        this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
          setTimeout(() => {
              this.show = state.show;
          });
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
