import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';

import { AuthService, AuthState } from 'src/app/shared/services/auth/auth.service';
import { catchError, tap, throttleTime } from 'rxjs/operators';
import { TokenResponse } from 'src/app/models/auth';

/** Pass untouched request through to the next request handler. */
// https://medium.com/@sajinsatheesan995/refresh-token-interceptor-angular-10-d876d01561be
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private throttleLogout = new Subject<void>(); //#UPDATE-comment

    constructor(
      private authService: AuthService,
    ) {
      // tslint:disable-next-line: deprecation
      this.throttleLogout.pipe(throttleTime(3000)).subscribe(() => {
        console.log('throttleLogout');
        this.authService.logout().subscribe();
      });
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

      if (this.authService.state?.access_token) {
        request = this.addToken(request);
      }

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          // When the token is expired, the api should return a error with new token attatched
            if (error.status === 401 &&  error.error?.refreshed === true) {
                this.authService.state = {
                  ...this.authService.state,
                  access_token: error.error.token,
                  expires_at: error.error.expires_at,
                };

                // retry the request with the new token
                const retriedRequest = request.clone({
                  setHeaders: { Authorization: `Bearer ${this.authService.state.access_token}` },
                });
                return next.handle(retriedRequest); 
            }else{
                return throwError(() => error);
            }
        })
      );
    }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        // 'X-Man': 'Ndershkuesi',
        Authorization: `Bearer ${this.authService.state?.access_token}`,
        'X-Timezone-Offset': this.getTimezoneOffset(),
      },
    });
  }


  // I return the timezone offset (in minutes) for the current Browser platform. This
  // is the number of minutes that the current timezone would have to add to a Date in
  // order to calculated the current UTC time.
  private getTimezoneOffset(): string {
    return (String(new Date().getTimezoneOffset()));

  }

}
