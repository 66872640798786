import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './front/not-found/not-found.component';

import { AuthGuard } from 'src/app/shared/guards/auth.guard';

import { NoAuthGuard } from './shared/guards/no-auth.guard';

const appRoutes: Routes = [

  // Auth
  {
    path: 'auth',
    canActivate: [ NoAuthGuard ],
    canLoad: [ NoAuthGuard ],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },

  // App aka Back
  {
    path: 'back',
    loadChildren: () => import('./back/back.module').then(m => m.BackModule),
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ]
  },

  // Public aka Front
  {
    path: '',
    loadChildren: () => import('./front/front.module').then(m => m.FrontModule),
  },

  // Global part
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes, {
    // preload all modules; optionally we could
    // implement a custom preloading strategy for just some
    // of the modules (PRs welcome )
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
    // relativeLinkResolution: 'legacy', // #UPDATE comment
    // enableTracing: true // for testing only
  }) ],
  exports: [ RouterModule ],
  providers: []
})
export class AppRoutingModule {
}

// this will be exported so we dont have dublication of
// component declarations here and in app modules
// export const routingComponents = [LoginComponent, DashboardComponent];
export const routingComponents = [];
