import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {DragDropModule} from '@angular/cdk/drag-drop';

// MOCK SERVICE FOR TESTING PURPOSES ONLY
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService } from 'src/app/services/in-memory-data.service';
// LOADER
import {LoaderComponent} from './loader/loader.component';
import {LoaderService} from './loader/loader.service';

// Interceptors
import {httpInterceptorProviders} from './shared/interceptors/interceptor';

// App components
import {AppComponent} from './app.component';
import {AppRoutingModule, routingComponents} from './app.routing';
import {NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {CustomDatepickerI18n} from './shared/services/datepicker-lang.service';
// import { SharedModule } from './shared/shared.module';
import {IntlTelInputNgModule} from 'intl-tel-input-ng';
import {QuillModule} from 'ngx-quill';

@NgModule({
  imports: [
    BrowserModule,
    // SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    DragDropModule,
    NgbModule,
    IntlTelInputNgModule.forRoot(),
    QuillModule.forRoot()

    // // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // // and returns simulated server responses.
    // // Remove it when a real server is ready to receive requests.
    // HttpClientInMemoryWebApiModule.forRoot(
    //   InMemoryDataService, { dataEncapsulation: false, delay: 750 }
    // )
  ],
  declarations: [
    AppComponent,
    LoaderComponent,
    routingComponents
  ],
  providers: [httpInterceptorProviders, LoaderService, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
  bootstrap: [AppComponent],
})
export class AppModule {
}
