import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './front/not-found/not-found.component';

import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { AdminGuard } from './shared/guards/admin.guard';

const appRoutes: Routes = [
  { path: 'maintenance', component: MaintenanceComponent },
  // { path: '**', redirectTo: '/maintenance' },  // In case of maintenance, uncomment this line

  // Auth
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    canLoad: [NoAuthGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },

  // App aka Back
  {
    canLoad: [AdminGuard],
    path: 'back',
    loadChildren: () => import('./back/back.module').then((m) => m.BackModule),
  },

  // Public aka Front
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./front/front.module').then((m) => m.FrontModule),
  },

  // Global part
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // preload all modules; optionally we could
      // implement a custom preloading strategy for just some
      // of the modules (PRs welcome )
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      // relativeLinkResolution: 'legacy', // #UPDATE comment
      // enableTracing: true // for testing only
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}

// this will be exported so we dont have dublication of
// component declarations here and in app modules
// export const routingComponents = [LoginComponent, DashboardComponent];
export const routingComponents = [];
