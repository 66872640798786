import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/loader/loader.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(private loaderService: LoaderService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        if (request.url.includes('/auth/tc-token')) {
            return next.handle(request);
        }

        if (!this.loaderService.getState()) {
            this.loaderService.show();
        }

        return next.handle(request).pipe(
            finalize(() => {
                if (this.loaderService.getState()) {
                    this.loaderService.hide();
                }
            })
        );
    }
}
