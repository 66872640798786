import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class BaseApiService {

    /**
     * The baseDomain is set in env
     * This variable is overwriten by the other services that extend this on.
     */
    public endpoint = '/';

    protected headers: HttpHeaders;

    constructor(
        private httpClient: HttpClient,
        private notification: NotificationService,
    ) {
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
    }

    getList<T>(uri?: number | string, reqOpts?: any): Observable<T> {
        const params = reqOpts ? this.createHttpParams(reqOpts) : {};

        return this.httpClient.get<T>(this.formatUri(uri), { params }).pipe(
            catchError(this.handleError([]))
        );
    }

    get(uri?: number | string, reqOpts?: any): Observable<any> {
        const params = reqOpts ? this.createHttpParams(reqOpts) : {};

        return this.httpClient.get(this.formatUri(uri), {params}).pipe(
            catchError(this.handleError([]))
        );
    }

    post(uri?: number | string, body?: any | null, reqOpts?: any, headers?: any): Observable<any> {
        const params = reqOpts ? this.createHttpParams(reqOpts) : {};

        return this.httpClient.post(
            this.formatUri(uri),
            body,
            {
                params,
                headers
            }
        );
    }

    put(uri?: number | string, body?: any | null, reqOpts?: any): Observable<any> {
        const params = reqOpts ? this.createHttpParams(reqOpts) : {};

        return this.httpClient.put(this.formatUri(uri), body, { params });
    }

    patch(uri?: number | string, body?: any | null, reqOpts?: any): Observable<any> {
        const params = reqOpts ? this.createHttpParams(reqOpts) : {};

        return this.httpClient.patch(this.formatUri(uri), body, { params });
    }

    delete(uri?: number | string, reqOpts?: any): Observable<any> {
        const params = reqOpts ? this.createHttpParams(reqOpts) : {};

        return this.httpClient.delete(this.formatUri(uri), { params });
    }

    /**
     * Prepare query parameters for requests'
     * @param params: any objet  {} with key  value
     */
    protected createHttpParams(params: any): HttpParams {

        let httpParams: HttpParams = new HttpParams();

        Object.keys(params).forEach(param => {
            if (params[param] != null) {
                httpParams = httpParams.set(param, params[param]);
            }
        });

        return httpParams;
    }

    /**
     * Format an incomming api
     * @param uri string URI endpoint
     */
    private formatUri(uri?: number | string): string {


        const fullUrl = `${environment.base_url}${this.endpoint}${uri || ''}`;

        // remove trailing slashes
        if (fullUrl.endsWith('/')) {
            return fullUrl.substring(0, fullUrl.length - 1);
        }

        return fullUrl;


        // let baseUrl = `${environment.base_url}${this.endpoint}`;

        // let endUrl = uri;

        // // remove trailing slashes
        // if (environment.base_url.endsWith('/')) {
        //     baseUrl = environment.base_url.substring(0, environment.base_url.length - 1);
        // }

        // // remove / at begining
        // if (!!uri && uri.startsWith('/')) {
        //     endUrl = uri.substring(1);
        // }

        // if (!!endUrl) {
        //     return baseUrl.concat('/', endUrl);
        // }

        // return baseUrl.concat(endUrl || '');
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(result?: T): any {
        return (error: any): Observable<T> => {

            // @todo: send the error to remote logging infrastructure
            console.error('An error occurred', error);

            this.notification.error(error.statusText, error.error.message);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
