import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private isInitialized = false;

  constructor(private authService: AuthService) {}

  loadChat(): void {
    console.log('Loading chat');
    if (this.isInitialized) {
      return;
    }

    if (!this.authService.isLoggedIn) {
      return;
    }
    this.isInitialized = true;

    this.addChatScript();
  }

  private addChatScript(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://gattinoni.my.site.com/ESWAtiturChat1729522873011/assets/js/bootstrap.min.js';
    script.onload = () => this.initEmbeddedMessaging();
    document.body.appendChild(script);
  }

  private initEmbeddedMessaging(): void {
    const authUser = this.authService.state?.user;
    try {
      const win = window as any;
      win.embeddedservice_bootstrap.settings.language = 'it';

      win.embeddedservice_bootstrap.init(
        '00D2o000000Z8l4',
        'Atitur_Chat',
        'https://gattinoni.my.site.com/ESWAtiturChat1729522873011',
        {
          scrt2URL: 'https://gattinoni.my.salesforce-scrt.com',
        },
      );

      const currentdate = new Date();

      window.addEventListener('onEmbeddedMessagingReady', () => {
        win.embeddedservice_bootstrap.prechatAPI.setVisiblePrechatFields({
          _firstName: { value: authUser?.first_name },
          _lastName: { value: authUser?.last_name },
          _email: { value: authUser?.email },
          Assistenza_Su: { value: '' },
          N_Pratica: {
            value: '',
          },
          Data_Partenza: { value: currentdate },
        });
        win.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
          Codice_Cliente: authUser?.agency?.operational_unit,
        });
      });
    } catch (err) {
      console.error('Error initializing Embedded Messaging: ', err);
    }
  }
}
