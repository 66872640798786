import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';

import { TokenService } from 'src/app/shared/services/storage/token.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { catchError, throttleTime } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
// https://medium.com/@sajinsatheesan995/refresh-token-interceptor-angular-10-d876d01561be
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private throttleLogout = new Subject<void>(); //#UPDATE-comment

    constructor(
      private authService: AuthService,
      private storageToken: TokenService
    ) {
      // tslint:disable-next-line: deprecation
      this.throttleLogout.pipe(throttleTime(3000)).subscribe(() => {
        console.log('throttleLogout');
        this.authService.logout();
      });
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

      if (this.storageToken.getToken()) {
        request = this.addToken(request);
      }

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // Call logout, but throttled!
            this.throttleLogout.next();
            return throwError(error);
          } else {
            return throwError(error);
          }
        })
      );
    }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        // 'X-Man': 'Ndershkuesi',
        Authorization: `${this.storageToken.getToken()}`,
        'X-Timezone-Offset': this.getTimezoneOffset()
      }
    });
  }


  // I return the timezone offset (in minutes) for the current Browser platform. This
  // is the number of minutes that the current timezone would have to add to a Date in
  // order to calculated the current UTC time.
  private getTimezoneOffset(): string {
    return (String(new Date().getTimezoneOffset()));

  }

}
