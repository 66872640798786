<!--<div class="container maintenance d-flex flex-column align-items-center text-center">-->
<!--  <img class="maintenance&#45;&#45;image mb-2" [src]="backgroundImg" alt="Site under maintenance">-->
<!--  <h2 class="maintenance&#45;&#45;title font-weight-bolder title">Stiamo migliorando il sito</h2>-->
<!--  <p class="maintenance&#45;&#45;text">NON SMETTERE DI SOGNARE IL TUO PROSSIMO VIAGGIO, TORNIAMO PRESTO!</p>-->
<!--</div>-->


<div class="container-fluid container-em">
  <div class="row">
    <div class="col-12 maintenance d-flex flex-column align-items-center justify-content-center text-center vh-100">
      <img class="maintenance--image mb-2 img-fluid" src="assets/img/maintenance/maintenance.svg" alt="Site under maintenance">
      <h2 class="maintenance--title font-weight-bolder title">Stiamo migliorando il sito</h2>
      <p class="maintenance--text">NON SMETTERE DI SOGNARE IL TUO PROSSIMO VIAGGIO, TORNIAMO PRESTO!</p>
    </div>
  </div>
</div>
