import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree, Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    // console.log('NoAuthGuard is called for canLoad', route);


    if (!!this.authService.isLoggedIn()) {
      this.router.navigate(['/home']);
      return false;
    }

    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // console.log('NoAuthGuard is called for canActivate', route);

    if (!!this.authService.isLoggedIn()) {
      this.router.navigate(['/home']);
      return false;
    }

    return true;
  }

}
