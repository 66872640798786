import { Injectable } from '@angular/core';
import { FactoryService } from './factory.service';

import { User } from 'src/app/models/user';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    private readonly currentUser = 'currentUser';
    private readonly modalShown = 'did_show_modal';
    private readonly authData = 'auth';
    private readonly tokenKey = 'token';
    private readonly refreshTokenKey = 'refresh_token';
    private readonly tokenType = 'Bearer';

    constructor(private storageFactory: FactoryService) { }

    resetAll(): void {
        this.storageFactory.removeItem(this.tokenKey);
        this.storageFactory.removeItem(this.authData);
        this.storageFactory.removeItem(this.currentUser);
        this.storageFactory.removeItem(this.refreshTokenKey);
        this.setDidShowModal(false);
    }

    // User
    setUser(user: User): void {
        this.storageFactory.setItem(this.currentUser, JSON.stringify(user));
    }

    getUser(): any {
        return JSON.parse(this.storageFactory.getItem(this.currentUser) || '{}');
    }

    removetUser(): void {
        return this.storageFactory.removeItem(this.currentUser);
    }

    // Auth user
    setAuth(auth: any): void {
        this.storageFactory.setItem(this.authData, JSON.stringify(auth));
    }

    getAuth(): any {
        return JSON.parse(this.storageFactory.getItem(this.authData) || '{}');
    }

    removetAuth(): void {
        return this.storageFactory.removeItem(this.authData);
    }

    // Auth Token
    setToken(token: string): void {
        this.storageFactory.setItem(this.tokenKey, ` ${this.tokenType} ${token}`);
    }

    getToken(): string | null {
        return this.storageFactory.getItem(this.tokenKey);
    }

    removetToken(): void {
        return this.storageFactory.removeItem(this.tokenKey);
    }

    // Refresh token
    setRefreshToken(refreshToken: string): void {
        this.storageFactory.setItem(this.refreshTokenKey, refreshToken);
    }

    getRefreshToken(): string | null {
        return this.storageFactory.getItem(this.refreshTokenKey);
    }

    removeRefreshToken(): void {
        return this.storageFactory.removeItem(this.refreshTokenKey);
    }

    // User
    setDidShowModal(value: boolean): void {
        this.storageFactory.setItem(this.modalShown, JSON.stringify(value));
    }

    getDidShowModal(): boolean {
        return JSON.parse(this.storageFactory.getItem(this.modalShown) || 'false');
    }
}
