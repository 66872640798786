import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


const I18N_VALUE = {

    weekdays: ['lun', 'mar', 'mer', 'gio', 'ven', 'sab', 'dom'],
    months: ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre',
        'ottobre', 'novembre', 'dicembre'],
        weekLabel: 'set'
    // other languages you would support
};


// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        throw new Error('Method not implemented.');
    }
    getWeekdayShortName(weekday: number): string { return I18N_VALUE.weekdays[weekday - 1]; }
    getWeekLabel(): string { return I18N_VALUE.weekLabel; }
    getMonthShortName(month: number): string { return I18N_VALUE.months[month - 1]; }
    getMonthFullName(month: number): string { return this.getMonthShortName(month); }
    getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
}
