import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './loader';

@Injectable()
export class LoaderService {

    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();
    status = false;

    constructor() { }

    show(): void {
        this.status = true;
        this.loaderSubject.next({ show: true });
    }

    hide(): void  {
        this.status = false;
        this.loaderSubject.next({ show: false });
    }

    getState(): boolean  {
        return this.status;
    }
    showLoader(par: LoaderState): Promise<LoaderState>  {
        // console.log('LoaderService showLoader called');
        this.loaderSubject.next({ show: true });
        return new Promise((resolve, reject) => {
            this.status = true;
            resolve(par);
        });
    }

    hideLoader(par: LoaderState): Promise<LoaderState> {
        // console.log('LoaderService hideLoader called');
        this.loaderSubject.next({ show: false });
        return new Promise((resolve, reject) => {
            this.status = false;
            resolve(par);
        });
    }
}
